<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        {{ pagename }}
      </v-card-title>
      <v-card-subtitle
        v-if="listtype == 'fop'"
        class="mb-3"
      >
        Cette page regroupe les FOPs dans lesquelles vous appartenez au circuit
        de validation, ou pour lesquelles vous avez le rôle d'observation.
      </v-card-subtitle>
      <v-card-subtitle
        v-else-if="listtype == 'event'"
        class="mb-3"
      >
        Cette page regroupe les évènements que vous (ou l'un de vos groupes)
        avez créés.
      </v-card-subtitle>
      <v-card-text
        v-if="!loading || items.length != 0 || pendingitems.length != 0"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            v-if="listtype == 'event'"
            class="mr-auto"
          >
            <v-btn
              elevation="1"
              outlined
              rounded
              :to="'/myevents/create'"
            >
              Créer un événement
            </v-btn>
          </v-col>
          <v-col
            v-if="listtype == 'fop'"
            cols="12"
            sm="4"
            md="3"
            class="ml-auto"
          >
            <InputSearch
              v-model="filter_group"
              type="group"
              outlined
              rounded
              hide-details="auto"
              dense
              label="Groupe"
              :text="(x) => x.name"
              clearable
            />
          </v-col>
          <v-col cols="auto">
            <v-btn-toggle v-model="filter_fopstatus">
              <v-btn
                v-if="listtype == 'event'"
                outlined
                value="no_fop"
              >
                <v-icon color="grey">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                value="progress"
              >
                <v-icon color="blue">
                  mdi-clock-outline
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                value="consent"
              >
                <v-icon color="green">
                  mdi-check-circle-outline
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                value="no_consent"
              >
                <v-icon color="error">
                  mdi-close-circle-outline
                </v-icon>
              </v-btn>
              <v-btn
                outlined
                value="cancelled"
              >
                <v-icon color="black">
                  mdi-cancel
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="auto">
            <v-checkbox
              v-model="past"
              label="Passé"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="auto"
          >
            <v-select
              v-model="sorting"
              :items="SORT"
              outlined
              dense
              label="Trier par"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <template v-if="pendingitems.length">
          <v-row class="my-0 py-0">
            <v-col
              v-for="x in pendingitems"
              :key="x._id"
              md="4"
              sm="6"
              cols="12"
            >
              <FopCard
                :event="x"
                pending
                @refresh="clear"
              />
            </v-col>
          </v-row>
          <v-divider class="mb-5 mt-2" />
        </template>
        <v-row>
          <v-col
            v-for="x in items"
            :key="x._id"
            md="4"
            sm="6"
            cols="12"
          >
            <FopCard
              v-if="listtype == 'fop'"
              :event="x"
              @refresh="clear"
            />
            <EventCard
              v-if="listtype == 'event'"
              :event="x"
              @refresh="clear"
            />
          </v-col>
        </v-row>
        <v-btn
          v-if="hasMore"
          outlined
          class="d-block mx-auto my-4"
          @click="more()"
        >
          Plus
        </v-btn>
        <v-row
          v-if="!items.length"
          justify="center"
          align="center"
        >
          <v-col cols="auto">
            <span
              v-if="listtype == 'fop'"
              class="text-h5"
            >Pas de FOP à afficher !</span>
            <span
              v-if="listtype == 'event'"
              class="text-h5"
            >Pas d'évènement à afficher !</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-progress-circular
        v-if="loading"
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>
    <FopModal
      v-if="listtype == 'fop'"
      v-model="modal_visible"
      :event_id="query_id"
      @refresh="clear"
    />
    <EventModal
      v-if="listtype == 'event'"
      v-model="modal_visible"
      :event_id="query_id"
      @refresh="clear"
    />
  </div>
</template>

<script>
import FopCard from "@/components/Fop/FopCard";
import FopModal from "@/components/Fop/FopModal";
import EventCard from "@/components/Event/EventCard";
import EventModal from "@/components/Event/EventModal";
import InputSearch from "@/components/Inputs/InputSearch";

export default {
  name: "MyList",
  components: { FopCard, FopModal, EventCard, EventModal, InputSearch },
  props: {
    listtype: {
      type: String,
      enum: ["fop", "event"],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      hasMore: true,
      past: false,
      items: [],
      pendingitems: [],
      // Data
      sorting: "event_details.begin_date",
      filter_fopstatus: "",
      filter_group: undefined,
      SORT: [
        { text: "Date de début", value: "event_details.begin_date" },
        { text: "Date de création de la FOP", value: "creation_date" },
      ],
    };
  },
  computed: {
    modal_visible: {
      get() {
        return !this.loading && !!this.query_id;
      },
      set() {
        if (this.listtype == "fop") {
          this.$router.replace({ route: "/myfops" });
        } else if (this.listtype == "event") {
          this.$router.replace({ route: "/myevents" });
        }
      },
    },
    query_id() {
      return this.$route.query.id;
    },
    pagename() {
      if (this.listtype == "fop") {
        return "Mes FOPs";
      } else if (this.listtype == "event") {
        return "Mes évènements";
      }
      return "";
    },
    pagedesc() {
      if (this.listtype == "fop") {
        return "Cette page regroupe les FOPs dans lesquelles vous appartenez au circuit de validation, ou dont vous avez le rôle d'observation.";
      } else if (this.listtype == "event") {
        return "Cette page regroupe les évènements dont vous (ou l'un de vos groupes) est à l'origine.";
      }
      return "";
    },
    endpoint() {
      if (this.listtype == "fop") {
        return "/fops/getFopsForUser";
      } else if (this.listtype == "event") {
        return "/events/getEventsForUser";
      }
      return "";
    },
  },
  watch: {
    sorting() {
      this.clear();
    },
    filter_fopstatus() {
      this.clear();
    },
    filter_group() {
      this.clear();
    },
    past() {
      this.clear();
    },
    listtype() {
      this.clear();
    },
  },
  created() {
    this.more();
  },
  methods: {
    more() {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + this.endpoint, {
        skip: this.items.length,
        filter_fopstatus: this.filter_fopstatus,
        filter_group:
          this.listtype == "fop" && this.filter_group && this.filter_group._id,
        sort: this.sorting,
        past: this.past,
      })
        .then((res) => {
          this.items.push(...res.data.items.filter((x) => !x.fop.pending));
          this.pendingitems.push(
            ...res.data.items.filter((x) => x.fop.pending)
          );
          this.hasMore = res.data.more;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    clear() {
      this.items = [];
      this.pendingitems = [];
      this.more();
    },
  },
};
</script>
