<template>
  <div>
    <v-timeline dense>
      <v-timeline-item
        v-for="x in getSteps"
        :key="x.i"
        fill-dot
        :color="getNiveauColor(x)"
        :large="x.ulti"
      >
        <v-card :disabled="isNiveauDisabled(x.i)">
          <v-card-title>
            {{ "Niveau " + x.i + (x.ulti ? " - ULTIMATE" : "") }}
          </v-card-title>
          <v-card-text class="pb-0">
            <v-divider />
            <v-timeline
              dense
              class="pa-0"
            >
              <v-timeline-item
                v-for="g in x.s"
                :key="g._id"
                fill-dot
                small
                class="pa-0"
                :color="getGroupColor(g)"
              >
                <template v-slot:icon>
                  <v-badge
                    content="?"
                    offset-x="-3"
                    offset-y="-2"
                    :value="!!g.optional"
                    class="tinybadge"
                  />
                </template>
                <v-card flat>
                  <v-card-title class="pb-0">
                    <v-row align="center">
                      <v-col>
                        {{ g.name }}
                      </v-col>
                      <v-spacer />
                      <v-col v-if="g.updated_at">
                        <span class="body-1 text-no-wrap font-weight-light">{{
                          g.updated_at | prettyDate
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text
                    v-if="isGroupWriteable(g)"
                    ckass="pb-0"
                  >
                    <v-textarea
                      v-model="comments[g._id]"
                      outlined
                      rows="1"
                      row-height="20"
                      auto-grow
                      hide-details
                      flat
                      label="Laisser un commentaire..."
                      solo
                    />
                  </v-card-text>
                  <v-card-text v-else-if="comments[g._id]">
                    <span
                      style="white-space: pre-wrap;"
                    >
                      {{ comments[g._id] }}
                    </span>
                  </v-card-text>
                  <v-card-actions
                    v-if="groupActions(g)"
                    class="float-right"
                  >
                    <v-btn
                      v-for="btn in groupActions(g)"
                      :key="btn.action"
                      depressed
                      :color="btn.color"
                      class="mr-1 white--text"
                      :loading="loading"
                      :disabled="loading"
                      @click="action(g, btn.action)"
                    >
                      {{ btn.text }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <TimelineHelp />
  </div>
</template>

<script>
import TimelineHelp from "@/components/Utils/TimelineHelp";

export default {
  components: { TimelineHelp },
  props: {
    event: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    getSteps() {
      let mappedSteps = {};
      // Map steps by level
      for (let x of this.event.fop.steps) {
        if (!mappedSteps[x.level]) mappedSteps[x.level] = [];
        mappedSteps[x.level].push({
          _id: x._id,
          name: x.group.name,
          optional: x.optional,
          user_permissions: x.user_permissions,
          step_status: x.step_status,
          level: x.level,
          updated_at: x.updated_at && new Date(x.updated_at),
        });
      }
      let maxlevel = Math.max(
        ...Object.keys(mappedSteps).map((x) => parseInt(x))
      );
      return Object.values(mappedSteps).map((x) => {
        return {
          s: x,
          i: x[0].level,
          ulti: x[0].level == maxlevel,
        };
      });
    },
    comments: {
      get() {
        let cs = {};
        for (let step of this.event.fop.steps) {
          cs[step._id] = step.comment || "";
        }
        return cs;
      },
      set(v) {
        this.event.fop.steps = v;
      },
    },
  },
  methods: {
    groupActions(g) {
      let btns = [];
      if (this.isGroupWriteable(g)) {
        if (g.optional) {
          btns.push(
            ...[
              {
                action: "consent",
                color: "green",
                text: "Favorable",
              },
              {
                action: "no_consent",
                color: "red",
                text: "Défavorable",
              },
            ]
          );
        } else {
          btns.push(
            ...[
              {
                action: "consent",
                color: "green",
                text: "Valider",
              },
              {
                action: "suspended",
                color: "orange",
                text: "Suspendre",
              },
              {
                action: "no_consent",
                color: "red",
                text: "Refuser",
              },
            ]
          );
        }
      }
      if (this.isGroupBypassable(g)) {
        btns.push({
          action: "bypassed",
          color: "blue",
          text: "Bypass",
        });
      }
      return btns;
    },
    getNiveauColor(x) {
      if (x.s.some((x) => x.step_status == "no_consent")) {
        return "error";
      }
      if (x.s.some((x) => x.step_status == "suspended")) {
        return "orange";
      }
      let lvl = this.event.fop.foplevel;
      if (lvl == -1) return "green"; // FOP FINISHED
      if (x.i < lvl) return "green";
      else if (x.i == lvl) return "blue";
      else return "grey lighten-1";
    },
    isNiveauDisabled(i) {
      return i > this.event.fop.foplevel;
    },
    getGroupColor(g) {
      return {
        waiting: "grey lighten-1",
        pending: "blue",
        consent: "green",
        no_consent: "error",
        suspended: "orange",
        bypassed: "blue-grey darken-1",
      }[g.step_status];
    },
    isGroupWriteable(g) {
      if (!g.user_permissions) return false;
      return g.user_permissions.write && g.step_status == "pending";
    },
    isGroupBypassable(g) {
      if (!g.user_permissions) return false;
      return g.user_permissions.bypass && g.step_status == "pending";
    },
    action(g, a) {
      this.loading = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/fops/doFOPaction", {
        _id: this.event._id,
        step_id: g._id,
        action: a,
        comment: this.comments[g._id],
      })
        .then(() => {
          g.user_permissions = { write: false, bypass: false };
          this.$emit("update");
          this.$alert.$emit("snackbar", {
            message: "OK",
            status: "info",
          });
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.tinybadge .v-badge__badge {
  font-size: 10px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  padding: 0.3em 4px 3px 4px;
}
</style>