<template>
  <v-dialog
    v-model="visibleDialog"
    max-width="1100"
  >
    <v-card class="pa-3" style="min-width: 700px;">
      <v-btn
        v-if="event"
        dark
        icon
        color="blue"
        absolute
        top
        right
        class="mt-2"
        @click="visibleDialog = false"
      >
        <v-icon
          left
          dark
          class="mx-auto"
        >
          mdi-close-circle-outline
        </v-icon>
      </v-btn>
      <template v-if="event">
        <v-card-title>
          <v-chip
            color="teal"
            class="mr-4"
            label
            text-color="white"
            small
          >
            {{ (event.fop.foptype && event.fop.foptype.name) || "N/A" }}
          </v-chip>
          <span>
            {{ event.name }}
          </span>
          <span class="ml-auto mr-5">
            {{ event.group.name }}
          </span>
        </v-card-title>
        <v-card-text class="pb-0">
          <Summary
            class="mb-3"
            :event="event"
          />
          <v-alert
            v-if="event.fop.status == 'cancelled'"
            class="mx-5"
            type="error"
            color="black"
          >
            Évènement annulé par le créateur !
          </v-alert>
          <v-row justify="end">
            <v-col
              v-if="areStepsModifiable"
              cols="auto"
            >
              <v-btn
                outlined
                color="primary"
                @click="editFopPath = true"
              >
                Modifier le parcours de FOP
              </v-btn>
            </v-col>
            <v-col
              v-if="isCancellable"
              cols="auto"
            >
              <v-btn
                outlined
                color="red"
                @click="cancel()"
              >
                Supprimer l'évènement (sysadmin)
              </v-btn>
            </v-col>
          </v-row>
          <FopTimeline
            :event="event"
            @update="abortChanges"
          />
        </v-card-text>
      </template>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
      <v-dialog
        v-model="editFopPath"
        persistent
      >
        <v-card class="mt-4 pa-4">
          <v-card-title>Modifier le parcours de FOP</v-card-title>
          <v-card-text>
            <EventTimeline
              v-if="areStepsModifiable"
              :event.sync="event"
              edit
            />
            <v-btn
              outlined
              color="green"
              class="mr-2"
              :loading="loadingChanges"
              :disabled="loadingChanges"
              @click="sendChanges()"
            >
              Valider
            </v-btn>
            <v-btn
              outlined
              :disabled="loadingChanges"
              @click="abortChanges()"
            >
              Annuler
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import FopTimeline from "@/components/Fop/FopTimeline";
import Summary from "@/components/Fop/Summary";
import EventTimeline from "@/components/Event/EventTimeline";

export default {
  name: "FopModal",
  components: {
    FopTimeline,
    Summary,
    EventTimeline,
  },
  props: {
    event_id: String,
    value: Boolean,
  },
  data() {
    return {
      event: undefined, // event détaillé !
      editFopPath: false,
      loadingChanges: false,
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    isCancellable() {
      return this.event.can_cancel;
    },
    areStepsModifiable() {
      if (!this.event || !this.event.fop.steps.length) return false;
      if (this.event.fop.status != "progress") return false;
      return this.event.fop.steps[0].user_permissions.modify_fop_steps;
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.updateFop();
      }
    },
  },
  methods: {
    updateFop() {
      this.event = undefined;
      this.axiosPost(process.env.VUE_APP_API_URL + "/fops/getFOPDetails", {
        _id: this.event_id,
      })
        .then((res) => {
          this.event = res.data;
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    abortChanges() {
      this.editFopPath = false;
      this.event = undefined;
      this.$emit("refresh");
      this.updateFop();
    },
    sendChanges() {
      this.loadingChanges = true;
      this.axiosPost(process.env.VUE_APP_API_URL + "/fops/updateFOPSteps", {
        _id: this.event_id,
        steps: this.event.fop.steps,
      })
        .then(() => {
          this.loadingChanges = false;
          this.abortChanges();
        })
        .catch((err) => {
          this.loadingChanges = false;
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    cancel() {
      this.$alert.$emit("confirm", {
        title: "Supprimer " + this.event.name + " ?",
        message:
          "Supprimer l'évènement (et la FOP associée) ? ! Cette action est irréversible !",
        callback: () => {
          this.axiosPost(process.env.VUE_APP_API_URL + "/admin/deleteEvent", {
            id: this.event_id,
          })
            .then(() => {
              this.$emit("refresh");
              this.$alert.$emit("snackbar", {
                message: "Évènement supprimé !",
                status: "success",
              });
              this.visibleDialog = false;
            })
            .catch((err) => {
              this.$alert.$emit("snackbar", {
                message: err.response.data,
                status: "error",
              });
            });
        },
      });
    },
  },
};
</script>
