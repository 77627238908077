<template>
  <v-dialog
    v-model="visibleDialog"
    max-width="1100"
  >
    <v-card class="pa-3">
      <v-btn
        v-if="event"
        dark
        icon
        color="blue"
        absolute
        top
        right
        class="mt-2"
        @click="visibleDialog = false"
      >
        <v-icon
          left
          dark
          class="mx-auto"
        >
          mdi-close-circle-outline
        </v-icon>
      </v-btn>
      <template v-if="event">
        <v-card-title>
          <v-chip
            color="teal"
            class="mr-4"
            label
            text-color="white"
            small
          >
            {{
              (event.fop && event.fop.foptype && event.fop.foptype.name) ||
                "N/A"
            }}
          </v-chip>
          <span>
            {{ event.name }}
          </span>
          <span class="ml-auto mr-5">
            {{ event.group.name }}
          </span>
        </v-card-title>
        <v-card-text class="pb-0">
          <Summary
            :event="event"
            class="mb-3"
          />
          <v-alert
            v-if="alertType"
            class="mx-5"
            :type="alertType"
            :icon="(alertType == 'warning' && 'mdi-alert') || undefined"
            :color="alertColor"
          >
            {{ event.fop.alertMessage }}
          </v-alert>
          <v-row justify="end">
            <v-col
              v-if="isEditable"
              cols="auto"
            >
              <v-btn
                outlined
                color="orange"
                @click="edit()"
              >
                Modifier la FOP
              </v-btn>
            </v-col>
            <v-col
              v-if="isCancellable"
              cols="auto"
            >
              <v-btn
                outlined
                color="alert"
                @click="cancel()"
              >
                {{ cancelWord }} l'évènement
              </v-btn>
            </v-col>
          </v-row>
          <Timeline
            v-if="event.fop && event.fop.steps && event.fop.steps.length"
            :event.sync="event"
          />
        </v-card-text>
      </template>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Summary from "@/components/Fop/Summary";
import Timeline from "@/components/Event/EventTimeline";

export default {
  name: "EventModal",
  components: {
    Summary,
    Timeline,
  },
  props: {
    event_id: String,
    value: Boolean,
  },
  data() {
    return {
      event: undefined, // event détaillé !
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    isEditable() {
      return (
        this.event.fop &&
        this.event.fop.status == "suspended" &&
        this.event.can_resume
      );
    },
    isCancellable() {
      return this.event.can_cancel;
    },
    cancelWord() {
      if (this.event.fop.status == "nofop") return "Supprimer";
      return "Annuler";
    },
    alertType() {
      if (!this.event.fop) return undefined;
      return {
        consent: "success",
        no_consent: "error",
        suspended: "warning",
        waiting: undefined,
        cancelled: "error",
      }[this.event.fop.status];
    },
    alertColor() {
      if (!this.event.fop) return undefined;
      return {
        consent: undefined,
        no_consent: undefined,
        suspended: undefined,
        waiting: undefined,
        cancelled: "black",
      }[this.event.fop.status];
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.updateEvent();
      }
    },
  },
  methods: {
    updateEvent() {
      this.event = undefined;
      this.axiosPost(process.env.VUE_APP_API_URL + "/events/getEventDetails", {
        id: this.event_id,
      })
        .then((res) => {
          this.event = res.data;
        })
        .catch((err) => {
          this.$alert.$emit("snackbar", {
            message: err.response.data,
            status: "error",
          });
        });
    },
    edit() {
      this.$router.push({
        name: "EventEdit",
        params: {
          edit: true,
          event_id: this.event_id,
        },
      });
    },
    cancel() {
      this.$alert.$emit("confirm", {
        title: this.cancelWord + " " + this.event.name + " ?",
        message:
          this.cancelWord + " l'évènement ? ! Cette action est irréversible !",
        callback: () => {
          this.axiosPost(process.env.VUE_APP_API_URL + "/events/cancelEvent", {
            id: this.event_id,
          })
            .then(() => {
              this.$emit("refresh");
              this.$alert.$emit("snackbar", {
                message: "Évènement annulé/supprimé !",
                status: "success",
              });
              this.visibleDialog = false;
            })
            .catch((err) => {
              this.$alert.$emit("snackbar", {
                message: err.response.data,
                status: "error",
              });
            });
        },
      });
    },
  },
};
</script>
