<template>
  <v-card
    class="fill-height d-flex flex-column s-clickable"
    @click="isModalVisible = true"
  >
    <v-card-title style="word-break: keep-all">
      {{ event.name }}
    </v-card-title>
    <v-card-subtitle>
      <v-col>
        <v-row dense>
          <v-icon
            small
            class="pr-3"
          >
            access_time
          </v-icon>
          <span v-if="is_same_date">
            {{ event_date | spanDate | capitalize }}
          </span>
          <span v-else>
            {{ event_date.start | prettyDate | capitalize }}<br>
            {{ event_date.end | prettyDate | capitalize }}
          </span>
        </v-row>
        <v-row
          dense
          class="mt-2"
        >
          <v-icon
            small
            class="pr-3"
          >
            mdi-map-marker
          </v-icon>
          <span v-if="event.event_details.locations.length">{{
            event.event_details.locations.map((x) => x.name) | displayList
          }}</span>
          <span v-else>Non spécifié</span>
        </v-row>
      </v-col>
    </v-card-subtitle>

    <v-spacer />
    <v-card-actions>
      <div class="text-xs-center">
        <v-chip
          :color="statusColor"
          text-color="white"
        >
          {{ statusText }}
        </v-chip>
      </div>
    </v-card-actions>
    <EventModal
      v-model="isModalVisible"
      :event_id="event._id"
      @refresh="$emit('refresh')"
    />
  </v-card>
</template>

<script>
import EventModal from "@/components/Event/EventModal";

export default {
  components: { EventModal },
  props: ["event"],
  data: () => ({
    isModalVisible: false,
  }),
  computed: {
    event_date() {
      return {
        start: this.event.event_details.begin_date,
        end: this.event.event_details.end_date,
      };
    },
    is_same_date() {
      let ds = new Date(this.event_date.start);
      let de = new Date(this.event_date.end);
      let day = ds.getDate() == de.getDate();
      let month = ds.getMonth() == de.getMonth();
      let year = ds.getFullYear() == de.getFullYear();
      return day && month && year;
    },
    status() {
      // use route to get fop level & status
      // progress / consent / no_consent
      return this.event.fop.status;
    },
    statusColor() {
      return {
        consent: "success",
        no_consent: "error",
        progress: "blue",
        suspended: "orange",
        nofop: "grey",
        cancelled: "black",
      }[this.status];
    },
    statusText() {
      return {
        consent: "FOP validée",
        no_consent: "FOP refusée",
        progress: "FOP en cours",
        suspended: "FOP suspendue",
        nofop: "Sans FOP",
        cancelled: "Évènement annulé",
      }[this.status];
    },
  },
};
</script>
