<template>
  <v-badge
    class="fill-height d-flex flex-column"
    content="!"
    color="red"
    :value="pending"
    overlap
  >
    <v-card
      class="fill-height d-flex flex-column"
      :color="color"
      @click="isModalVisible = true"
    >
      <!-- Event basic infos -->
      <v-card-title class="white--text">
        <v-row align="center">
          <v-col cols="auto">
            {{ event.name | ellipisize(16) }}
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
            class="text-body-2"
          >
            <span>
              {{ event.event_details.begin_date | regularDate }}
            </span>
            <span>
              ({{ (upToDate > 0 && "dans") || "il y a" }}
              <b>{{ abs(upToDate) }}</b> jours)
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle />
      <v-card-text class="white--text">
        <span>{{ ellispsis(event.event_details.description) }}</span>
      </v-card-text>

      <v-spacer />
      <v-divider light />

      <!-- event actions -->
      <v-card-actions class="py-3 mx-8 white--text">
        <v-progress-linear
          :color="progressColor"
          height="5"
          :value="event.fop.status_progress"
          class="mr-8"
        />

        <v-spacer />

        <span class="font-weight-medium">{{ event.group.name }}</span>

        <v-spacer />

        <!-- <TooltipBtn
          tooltip="Télécharger la fop"
          icon="mdi-file-pdf-box"
          icononly
          :color="flagColor"
        /> -->
      </v-card-actions>
      <FopModal
        v-model="isModalVisible"
        :event_id="event._id"
        @refresh="$emit('refresh')"
      />
    </v-card>
  </v-badge>
</template>

<script>
import FopModal from "@/components/Fop/FopModal";
import utils from "@/utils";
// import TooltipBtn from "../Inputs/TooltipBtn.vue";

export default {
  components: {
    FopModal,
    // TooltipBtn,
  },
  props: {
    event: Object,
    pending: Boolean,
  },
  data: function () {
    return {
      isModalVisible: false,
      flagColor: "white",
      progressColor: "white",
    };
  },
  computed: {
    upToDate() {
      return utils.datediff(
        new Date(this.event.event_details.begin_date),
        new Date()
      );
    },
    color() {
      return {
        progress: "blue",
        consent: "green",
        no_consent: "error",
        suspended: "orange",
        cancelled: "black",
      }[this.event.fop.status];
    },
  },
  methods: {
    ellispsis: utils.ellispsis,
    abs: Math.abs,
  },
};
</script>
